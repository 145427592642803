import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  Input,
  Link,
  ButtonGroup,
  Box,
  HStack,
  Center,
  Image,
} from '@chakra-ui/react'
import {
  EditIcon,
  CloseIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  SearchIcon,
} from '@chakra-ui/icons'
import { useEffect, useState } from "react"

import { Token } from "../types"
import useTokens from "../hooks/useTokens"
import { wagmi } from "../apis"
import { isAddress } from 'ethers/lib/utils'
import { useNavigate } from 'react-router-dom'
import { PAGE_LIMIT } from '../consts'
import ImageUploader from '../components/ImageUpload'


const Tokens = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState("")
  const { tokens, getTokens, isLoading } = useTokens({ page })

  const [selected, setSelected] = useState<Partial<Token>>()

  const updateToken = async () => {
    wagmi({
      method: "put",
      url: `/tokens`,
      data: {
        address: selected?.address,
        name: selected?.name,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        setSelected(undefined)
        getTokens()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (isAddress(query)) {
      navigate(`/tokens/${query.toLowerCase()}`)
    }
  }, [query])

  return (
    <Box>
      <Input mt="8" mb="8" value={query} onChange={e => setQuery(e.target.value)} placeholder="Address" />
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Icon</Th>
            <Th>Symbol</Th>
            <Th>Name</Th>
            <Th>Address</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tokens.map((token: Token) => {
            const isSelected = selected?.address === token.address

            return (
              <Tr key={token.address}>
                <Td>{isSelected ? (
                  <ButtonGroup>
                    <IconButton onClick={() => setSelected(undefined)} aria-label="cancel" icon={<CloseIcon />} />
                    <IconButton onClick={updateToken} aria-label="submit" icon={<CheckIcon />} />
                  </ButtonGroup>
                ) : <IconButton onClick={() => setSelected(token)} aria-label="edit" icon={<EditIcon />} />}</Td>
                <Td>{isSelected ? <ImageUploader onUpload={image => setSelected({ ...selected, image })} placeholder={token.image} /> : <Image src={token.image} w="40px" alt={token.name} />}</Td>
                <Td>{isSelected ? <Input onChange={(e) => setSelected({ ...selected, symbol: e.target.value })} value={token?.symbol}></Input> : <Text fontSize='md'>{token?.symbol}</Text>}</Td>
                <Td>{isSelected ? <Input onChange={(e) => setSelected({ ...selected, name: e.target.value })} value={token?.name}></Input> : <Text fontSize='md'>{token?.name}</Text>}</Td>
                <Td>
                  <Link href={`https://etherscan.io/token/${token.address}`} isExternal>
                    {token.address}<ExternalLinkIcon mx='5px' /></Link>
                </Td>

              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Center p="8">
        <HStack spacing="12">
          <IconButton isLoading={isLoading} isDisabled={isLoading || page === 0} onClick={() => setPage(page - 1)} aria-label="prev" icon={<ChevronLeftIcon />} />
          <Text fontWeight="black">{page}</Text>
          <IconButton isLoading={isLoading} isDisabled={isLoading || tokens.length < PAGE_LIMIT} onClick={() => setPage(page + 1)} aria-label="next" icon={< ChevronRightIcon />} />
        </HStack>
      </Center>
    </Box>
  )
}

export default Tokens
