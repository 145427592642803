import { useCallback, useEffect, useState } from "react"
import { wagmi } from "../apis"

const useFetch = <T extends any>(url: string) => {
  const [data, setData] = useState<T>()
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState()

  const getData = useCallback(async () => {
    toggleLoading(true)

    wagmi({
      method: "get",
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setData(res.data)
        toggleLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleLoading(false)
      })

  }, [url])

  useEffect(() => {
    getData()
  }, [getData])

  return {
    data,
    isLoading,
    error,
  }
}

export default useFetch
