import { createContext, useContext, useLayoutEffect, useState } from "react"
import { wagmi } from '../apis'

interface AuthContextType {
  jwt?: string | undefined | null
  user: any
  signin: (user: string, callback?: VoidFunction) => void
  signout: (callback?: VoidFunction) => void
}

const AuthContext = createContext<AuthContextType>(null!)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<any>(null)
  const [jwt, setJwt] = useState<string | undefined | null>(sessionStorage.getItem("token"))

  useLayoutEffect(() => {
    const token = sessionStorage.getItem("token")
    if (typeof token === 'string') {
      setJwt(token)
    }
  }, [])

  const signin = (token: string, callback?: VoidFunction) => {
    sessionStorage.setItem('token', token)
    setJwt(token)
    wagmi.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  const signout = (callback?: VoidFunction) => {
    sessionStorage.removeItem('token')
    setJwt(undefined)
  }

  let value = { user, signin, signout, jwt }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}