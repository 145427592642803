import { useCallback, useEffect, useState } from "react"
import { Method } from "../types"
import { wagmi } from "../apis"

const useMethods = (params: { page?: number }) => {
  const [methods, setMethods] = useState<Method[]>([])
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState("")

  const { page } = params

  const getMethods = useCallback(async () => {
    toggleLoading(true)
    wagmi({
      method: "get",
      url: `/methods?page=${page}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        toggleLoading(false)
        setMethods(res.data)
      })
      .catch((error) => {
        setError(error)
        toggleLoading(false)
        console.log(error)
      })
  }, [page])

  useEffect(() => {
    getMethods()
  }, [getMethods])

  return { methods, getMethods, setMethods, isLoading, error }
}

export default useMethods