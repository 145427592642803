import { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Image,
  Input,
} from '@chakra-ui/react'

import { pinata } from '../apis';


const ImageUploader = ({ onUpload, placeholder }: { onUpload: (url: string) => void, placeholder?: string }) => {
  const [previewImg, setPreviewImg] = useState<string>()

  useEffect(() => {
    if (placeholder) {
      setPreviewImg(placeholder)
    }
  }, [placeholder])

  const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const url = URL.createObjectURL(file)
      setPreviewImg(url)

      let data = new FormData();
      data.append('file', file);
      const pinned = await uploadToPinata(data)
      console.log('pinned', pinned)

      if (pinned && pinned.data && pinned?.data.IpfsHash) {
        onUpload(`https://interface.mypinata.cloud/ipfs/${pinned.data.IpfsHash}`)
      }
    }
  };

  const uploadToPinata = async (data: FormData) => await pinata.post('/pinning/pinFileToIPFS', data)

  return (
    <Flex>
      <Input onChange={upload} type="file" />
      {previewImg ? <Image src={previewImg} style={{ width: 40, height: 40, borderRadius: 40, marginLeft: 15 }} /> : <div style={{ width: 40, height: 40, borderRadius: 40, marginLeft: 15, background: 'linear-gradient(135deg, #DBFF00 0%, #FF5CD1 100%)' }} />}
    </Flex>
  )
}

export default ImageUploader
