import { useCallback, useEffect, useState } from "react"
import { Token } from "../types"
import { wagmi } from "../apis"

const useTokens = (params: { page: number }) => {
  const [tokens, setTokens] = useState<Token[]>([])
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState("")

  const { page } = params

  const getTokens = useCallback(async () => {
    toggleLoading(true)
    wagmi({
      method: "get",
      url: `/tokens?page=${page}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        toggleLoading(false)
        setTokens(res.data)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleLoading(false)
      })
  }, [page])

  useEffect(() => {
    getTokens()
  }, [getTokens])

  return { tokens, getTokens, setTokens, isLoading, error }
}

export default useTokens
