import {
  Text,
  Link,
  Button,
  Stack,
  Divider,
  Image,
  HStack,
  Table,
  Td,
  Tr,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  Tbody,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom'

import useProtocol from '../hooks/useProtocol'

const Protocol = () => {
  const { id } = useParams()
  const { protocol, setProtocol, updateProtocol, getProtocol, isDisabled, isLoading, error } = useProtocol(id)

  if (!protocol) return null

  return (
    <Stack spacing="4" p="4" pb="12">
      <HStack spacing="4" alignItems="flex-start">
        <Image src={protocol.logo_url} w="100px" alt={protocol.name} />
        <Stack spacing="4" w="full">
          <Text fontWeight="bold">Protocol: #{protocol.id} </Text>
          <Editable defaultValue="Name" value={protocol.name}>
            <EditablePreview>{protocol.name}</EditablePreview>
            <EditableInput placeholder="name" onChange={(e) => setProtocol({ ...protocol, name: e.target.value })} value={protocol.name} />
          </Editable>
          <Editable defaultValue="Description" value={protocol.description}>
            <EditablePreview>{protocol.description}</EditablePreview>
            <EditableTextarea placeholder="description" onChange={(e) => setProtocol({ ...protocol, description: e.target.value })} value={protocol.description} />
          </Editable>
        </Stack>
      </HStack>
      <Divider />
      <Text>Contracts</Text>
      <Table>
        <Tbody>
          {protocol?.contracts?.map(contract => (
            <Tr key={contract.address}>
              <Td><Text>{contract.name}</Text></Td>
              <Td textAlign="right"><Text>{contract.address}</Text></Td>
              <Td><Button as={Link} href={`https://etherscan.io/address/${contract.address}#code`} isExternal rightIcon={<ExternalLinkIcon />} aria-label="etherescan contract">Etherscan</Button></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Stack>
  )
}

export default Protocol
