import { useCallback, useEffect, useState } from "react"
import { wagmi } from "../apis"

interface Statistics {
  connections: number
  connections_24_hours_ago: number
  users: number
  users_24_hours_ago: number
  users_indexed: number
  users_indexed_24_hours_ago: number
  // transactions: number
  // transactions_24_hours_ago: number
  methods: number
  methods_total: number
  collections: number
  tokens: number
  labeled_contracts: number
  protocols: number
}

const defaultStats = {
  connections: 0,
  connections_24_hours_ago: 0,
  users: 0,
  users_24_hours_ago: 0,
  users_indexed: 0,
  users_indexed_24_hours_ago: 0,
  // transactions: 0,
  // transactions_24_hours_ago: 0,
  methods: 0,
  methods_total: 0,
  collections: 0,
  tokens: 0,
  labeled_contracts: 0,
  protocols: 0,
}


const useStatistics = () => {
  const [statistics, setStatistics] = useState<Statistics>(defaultStats)
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState()

  const getStats = useCallback(async () => {
    toggleLoading(true)
    wagmi({
      method: "get",
      url: `/stats`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setStatistics(res.data)
        toggleLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleLoading(false)
      })
  }, [])

  useEffect(() => {
    getStats()
  }, [getStats])

  return {
    statistics,
    isLoading,
    error,
  }
}

export default useStatistics
