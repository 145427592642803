import "inter-ui/inter.css";
import '@rainbow-me/rainbowkit/styles.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { BrowserRouter } from "react-router-dom";
import { mainnet } from 'wagmi/chains';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit'
import {
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'

import theme from './theme'
import './index.css';
import App from './App';
import { AuthProvider } from './state/auth';

if (!process.env.REACT_APP_ALCHEMY_KEY) {
  throw new Error("no provider API KEY")
}

const { chains, provider } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_KEY }),
    publicProvider()
  ]
)

const { connectors } = getDefaultWallets({
  appName: 'Interface Particles Spawner',
  chains,
})

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

const container = document.getElementById('root');
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains} showRecentTransactions={true}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);