import axios from "axios"

const interface_api_url = process.env.REACT_APP_INTERFACE_API_URL
const pinata_api_key = process.env.REACT_APP_PINATA_API_KEY as string
const pinata_secret_api_key = process.env.REACT_APP_PINATA_SECRET_API_KEY as string
const etherscan_api_key = process.env.REACT_APP_ETHERSCAN_API_KEY

export const api = axios.create({
  baseURL: interface_api_url
})

export const wagmi = axios.create({
  baseURL: interface_api_url + '/wagmi',
  headers: {
    "Authorization": `Bearer ${sessionStorage.getItem("token")}`
  }
})

export const pinata = axios.create({
  baseURL: 'https://api.pinata.cloud',
  headers: {
    pinata_api_key,
    pinata_secret_api_key,
  },
})


export const etherscan = axios.create({
  baseURL: 'https://api.etherscan.io/api',
  params: {
    apikey: etherscan_api_key,
  },
})
