import { Box, Button, ButtonGroup, StatLabel, Stat, StatNumber, Switch, Flex, Text } from '@chakra-ui/react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, } from 'recharts';

import useTotalUsers from '../hooks/useTotalUsers'
import useNewUsers, { CumulativeData, Period, Range } from '../hooks/useNewUsers'

import { useMemo, useState } from 'react';
import { calculateDelta } from '../utils';

const Chart = ({ cumulative, data }: { cumulative: boolean, data: CumulativeData[] }) => {

  if (cumulative) {
    return (
      <ResponsiveContainer width="100%" height="80%">
        <LineChart data={data}>
          <XAxis dataKey="date" />
          <YAxis orientation="right" />
          <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ border: 'none', background: 'transparent' }} contentStyle={{ backgroundColor: '#101418', borderRadius: '8px', border: 'none' }} />
          <Line type="monotone" dataKey="num_verified_cumulative" fill='#199af5' strokeWidth={"4"} stroke="#199af5" />
        </LineChart>
      </ResponsiveContainer>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="80%">
      <BarChart data={data}>
        <XAxis dataKey="date" />
        <YAxis orientation="right" />
        <Tooltip cursor={{ fill: 'transparent' }} wrapperStyle={{ border: 'none', background: 'transparent' }} contentStyle={{ backgroundColor: '#101418', borderRadius: '8px', border: 'none' }} />
        <Bar dataKey="num_verified" fill="#199af5" radius={[6, 6, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  )
}

const Traction = () => {
  const [period, setPeriod] = useState<Period>("weekly")
  const [range, setRange] = useState<Range>("week")

  const [cumulative, toggleCumulative] = useState<boolean>(false)
  const { data: totalUsers, error, isLoading } = useTotalUsers()
  const { data } = useNewUsers(period, range)

  const prevTotalUsers = useMemo(() => data?.length > 2 ? data[data.length - 2].num_verified_cumulative : 0, [data])
  const usersDelta = useMemo(() => calculateDelta(totalUsers, prevTotalUsers), [totalUsers, prevTotalUsers])

  return (
    <Box p="16" minW="300px" minH="100px">
      <Flex justify="space-between" align="center">
        <Flex align="center">
          <ButtonGroup size='md' isAttached variant='ghost'>
            <Button variant={period === "daily" ? "solid" : "ghost"} onClick={() => setPeriod("daily")}>Daily</Button>
            <Button variant={period === "weekly" ? "solid" : "ghost"} onClick={() => setPeriod("weekly")}>Weekly</Button>
            <Button variant={period === "monthly" ? "solid" : "ghost"} onClick={() => setPeriod("monthly")}>Monthly</Button>
          </ButtonGroup>
          {period === "daily" && (
            <ButtonGroup size='md' isAttached variant='outline' ml="4">
              <Button variant={range === "week" ? "solid" : "outline"} onClick={() => setRange("week")}>7d</Button>
              <Button variant={range === "month" ? "solid" : "outline"} onClick={() => setRange("month")}>30d</Button>
              <Button variant={range === "year" ? "solid" : "outline"} onClick={() => setRange("year")}>Year</Button>
              <Button variant={range === "all_time" ? "solid" : "outline"} onClick={() => setRange("all_time")}>All time</Button>
            </ButtonGroup>
          )}
        </Flex>

        <Flex align="center">
          <Text mr="4">Cumulative</Text>
          <Switch isChecked={cumulative} onChange={() => toggleCumulative(!cumulative)} size="lg" />
        </Flex>
      </Flex>
      <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100wv", height: '500px', marginTop: '30px', padding: '15px', backgroundColor: '#101418', borderRadius: '12px', }}>
        <Stat p="8" m="2" borderRadius="xl" style={{ position: 'absolute', top: 0, left: 0 }}>
          <StatLabel>Verified&nbsp;members</StatLabel>
          <Flex align="center">
            <StatNumber>{totalUsers}</StatNumber>
            <StatNumber fontSize="lg" ml="2" color="green.400">
              {`+${usersDelta}%`}
            </StatNumber>
          </Flex>

        </Stat>
        <Chart cumulative={cumulative} data={data} />
      </div>
    </Box >
  )
}

export default Traction

