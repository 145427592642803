import { Button, Center, Checkbox, Stack } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { SiweMessage } from 'siwe'

import { api } from "../apis";
import { useAuth } from "../state/auth";

const useLogin = () => {
  const { signin, signout, user, jwt } = useAuth();
  const { address } = useAccount()
  const { chain } = useNetwork()
  const { signMessageAsync } = useSignMessage()
  const [isLoading, toggleIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  let navigate = useNavigate();
  let location = useLocation();

  const state = location.state as State
  let from = state?.from?.pathname || "/";
  const isDisabled = useMemo(() => isLoading, [isLoading])

  const login = useCallback(async () => {
    toggleIsLoading(true)

    try {
      const paramsRes = await api({
        method: 'get',
        url: '/gm'
      })

      const message = new SiweMessage({
        domain: window.location.host,
        address: address,
        statement: paramsRes.data.statement,
        uri: window.location.origin,
        version: paramsRes.data.version,
        chainId: chain?.id,
        nonce: paramsRes.data.nonce,
      })

      const signature = await signMessageAsync({ message: message.prepareMessage() })

      const verifyRes = await api({
        method: 'post',
        url: '/gm',
        data: {
          message,
          signature: signature,
        }
      })
      if (verifyRes.status !== 200) throw new Error('error verifying message')

      signin(verifyRes.data)
      toggleIsLoading(false)
      navigate(from, { replace: true })
    } catch (err) {
      console.log('error', err)
      toggleIsLoading(false)
      setError(err)
    }
  }, [signMessageAsync, address, chain])

  return {
    login,
    isLoading,
    isDisabled,
    error,
  }
}

interface State {
  from: {
    pathname: string
  }
}

const Login = () => {
  const { login, isLoading, isDisabled, error } = useLogin()
  const { isConnected } = useAccount()
  const { chain } = useNetwork()

  const isOptimismic = useMemo(() => chain?.id === 10 ? true : false, [chain])
  // const isCore = useMemo(() => data?.address ? true : false, [data])

  return (
    <Center p="4" pt="36" alignContent="center">
      {/* <p>You must log in to view the page at {from}</p> */}
      <Stack spacing="4" w="md" p="4" borderRadius="md">
        <Checkbox size='lg' colorScheme='green' isDisabled isChecked={isConnected}>Address connected</Checkbox>
        <Checkbox size='lg' colorScheme='green' isDisabled isChecked={isOptimismic}>On Optimism network</Checkbox>
        <Checkbox size='lg' colorScheme='green' isDisabled>HODL letmein NFT</Checkbox>
        <Button
          // leftIcon={<EthIcon />}
          width="full"
          isLoading={isLoading}
          isDisabled={isDisabled}
          onClick={login}
        >
          Sign-In with Ethereum
        </Button>
      </Stack>

    </Center>
  );
}

export default Login
