import { Routes, Route } from "react-router-dom";

import Contract from './pages/Contract'
import Contracts from './pages/Contracts'
import Protocol from './pages/Protocol'
import Protocols from './pages/Protocols'
import Methods from './pages/Methods'
import Home from './pages/Home'

import Layout from "./components/Layout";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import Tokens from "./pages/Tokens";
import Whitelisted from "./pages/Whitelisted";
import Traction from "./pages/Traction";

const App = () => {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route index element={<RequireAuth><Home /></RequireAuth>} />
        <Route path="contracts" element={<RequireAuth><Contracts /></RequireAuth>} />
        <Route path="contracts/:address" element={<RequireAuth><Contract /></RequireAuth>} />
        <Route path="protocols" element={<RequireAuth><Protocols /></RequireAuth>} />
        <Route path="protocols/:id" element={<RequireAuth><Protocol /></RequireAuth>} />
        <Route path="methods" element={<RequireAuth><Methods /></RequireAuth>} />
        <Route path="/tokens" element={<RequireAuth><Tokens /></RequireAuth>} />
        <Route path="/addresses" element={<RequireAuth><Whitelisted /></RequireAuth>} />
        <Route path="/traction" element={<RequireAuth><Traction /></RequireAuth>} />
      </Route>
    </Routes>
  )
}

export default App
