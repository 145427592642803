import { useCallback, useEffect, useMemo, useState } from "react"
import { Contract } from "../types"
import { wagmi } from "../apis"

const useContract = (address?: string) => {
  const [contract, setContract] = useState<Contract>()
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState("")

  const getContract = useCallback(async () => {
    if (!address) return

    toggleLoading(true)
    wagmi({
      method: "get",
      url: `/contracts/${address}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        toggleLoading(false)
        setContract(res.data)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleLoading(false)
      })
  }, [address])

  const updateContract = useCallback(async () => {
    wagmi({
      method: "put",
      url: `/contracts`,
      data: {
        address: contract?.address,
        name: contract?.name,
        protocol_id: contract?.protocol?.id,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        getContract()
      })
      .catch((error) => console.log(error))
  }, [contract])

  const syncContractABI = useCallback(async () => {
    wagmi({
      method: "put",
      url: `/contracts/${contract?.address}`,
    })
      .then((res) => {
        getContract()
      })
      .catch((error) => console.log(error))
  }, [contract])

  useEffect(() => {
    getContract()
  }, [getContract])

  const isDisabled = useMemo(() => isLoading, [isLoading])

  return { contract, getContract, setContract, updateContract, syncContractABI, isLoading, isDisabled, error }
}

export default useContract