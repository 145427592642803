import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'

import useProtocols from '../hooks/useProtocols';
import useProtocol from '../hooks/useProtocol';
import { Contract } from '../types';
import ImageUploader from '../components/ImageUpload';
import { useNavigate } from 'react-router-dom';

const Protocols = () => {
  const { protocols } = useProtocols()
  const { protocol, setProtocol, addProtocol, isLoading, isDisabled } = useProtocol()
  const navigate = useNavigate()

  return (
    <Box p="4">
      <Stack spacing={3} pb="4">
        <ImageUploader onUpload={logo_url => setProtocol({ ...protocol, logo_url })} />
        <Input placeholder='Name' onChange={e => setProtocol({ ...protocol, name: e.target.value })} />
        <Input placeholder='Description' onChange={e => setProtocol({ ...protocol, description: e.target.value })} />
        <Input placeholder='Website' onChange={e => setProtocol({ ...protocol, website: e.target.value })} />
        <Input placeholder='Twitter' onChange={e => setProtocol({ ...protocol, twitter: e.target.value })} />
        <Button onClick={addProtocol} isLoading={isLoading} isDisabled={isDisabled}>Add protocol</Button>
      </Stack>
      <Divider />
      <Stack spacing="4">
        <Text pt="4">{protocols.length} protocols in our database</Text>

        {protocols.map((protocol) => (
          <Flex key={protocol.id} pt="4" onClick={() => navigate(`/protocols/${protocol.id}`)}>
            {protocol.logo_url && <Image src={protocol.logo_url} style={{ width: 60, height: 60, borderRadius: 60, marginRight: 15 }} />}
            <Box>
              <Flex>
                <Text fontSize="2xl" fontWeight="black">{protocol.name}</Text>
                {/* <Link href={protocol.twitter} target="_blank">
                <TwitterLogoIcon />
              </Link>
              <Link href={protocol.website} target="_blank">
                <Link2Icon />
              </Link> */}
              </Flex>

              <Text>{protocol.description}</Text>
              {protocol.contracts && (
                <Stack spacing="1" mt="2">
                  <Text fontWeight="bold">Contracts {protocol.contracts.length}</Text>
                  {protocol?.contracts.map((contract: Contract) => (
                    <Flex key={contract.address} justify="space-between">
                      <Text>{contract.name}</Text>
                      <Text>{contract.address}</Text>
                    </Flex>
                  ))}
                </Stack>
              )}
            </Box>
          </Flex>
        ))}
      </Stack>
    </Box>
  )
}

export default Protocols