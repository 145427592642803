import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  Input,
  Tag,
  TagLabel,
  Avatar,
  Link,
  Select,
  ButtonGroup,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  HStack,
  Center,
} from '@chakra-ui/react'
import {
  EditIcon,
  CloseIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
  SearchIcon,
} from '@chakra-ui/icons'
import { useEffect, useMemo, useState } from "react"

import { Contract } from "../types"
import { formatAddress } from "../utils"
import useProtocols from "../hooks/useProtocols"
import useContracts from "../hooks/useContracts"
import { wagmi } from "../apis"
import useContract from '../hooks/useContract'
import { isAddress } from 'ethers/lib/utils'
import { useNavigate } from 'react-router-dom'
import { PAGE_LIMIT } from '../consts'


const Contracts = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState("")
  const { protocols } = useProtocols()
  const { contracts, getContracts, isLoading } = useContracts({ page })

  const [selected, setSelected] = useState<Partial<Contract>>()

  const updateContract = async () => {
    wagmi({
      method: "put",
      url: `/contracts`,
      data: {
        address: selected?.address,
        name: selected?.name,
        protocol_id: selected?.protocol?.id,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setSelected(undefined)
        getContracts()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (isAddress(query)) {
      navigate(`/contracts/${query.toLowerCase()}`)
    }
  }, [query])


  return (
    <Box>
      <Input mt="8" mb="8" value={query} onChange={e => setQuery(e.target.value)} placeholder="Address" />
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Name</Th>
            <Th>Address</Th>
            <Th>Protocol</Th>
            <Th>Type</Th>
            <Th>Abi</Th>
          </Tr>
        </Thead>
        <Tbody>
          {contracts.map((contract: Contract) => {
            const isSelected = selected?.address === contract.address

            return (
              <Tr key={contract.address}>
                <Td>{isSelected ? (
                  <ButtonGroup>
                    <IconButton onClick={() => setSelected(undefined)} aria-label="cancel" icon={<CloseIcon />} />
                    <IconButton onClick={updateContract} aria-label="submit" icon={<CheckIcon />} />
                  </ButtonGroup>
                ) : <IconButton onClick={() => setSelected(contract)} aria-label="edit" icon={<EditIcon />} />}</Td>
                <Td>{isSelected ? <Input onChange={(e) => setSelected({ ...selected, name: e.target.value })} value={contract?.name}></Input> : <Text fontSize='md'>{contract?.name}</Text>}</Td>
                <Td>
                  <Link href={`https://etherscan.io/address/${contract.address}#code`} isExternal>
                    {formatAddress(contract.address)}<ExternalLinkIcon mx='5px' /></Link>
                </Td>
                <Td>
                  {isSelected ? (
                    <Select value={selected?.protocol?.id} onChange={(e) => setSelected({ ...selected, protocol: protocols.find(p => p.id.toString() === e.target.value) })} placeholder="Select protocol">
                      {protocols.map(protocol => <option value={protocol.id} key={protocol.id}>{protocol.name}</option>)}
                    </Select>
                  ) : contract?.protocol && (
                    <Tag size="md">
                      <Avatar
                        src={contract?.protocol?.logo_url}
                        size='2xs'
                        name={contract?.protocol?.name}
                        ml={-1}
                        mr={2}
                      />
                      <TagLabel>{contract?.protocol?.name}</TagLabel>
                    </Tag>
                  )}
                </Td>
                <Td>
                  {contract?.type && <Tag size="md">{contract.type}</Tag>}
                </Td>
                <Td>
                  {contract.abi && <Button as={Link} href={`https://interface.mypinata.cloud/ipfs/${contract.abi_ipfs_hash}`} isExternal rightIcon={<ExternalLinkIcon />} aria-label="ipfs pinned contract abi">ABI</Button>}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Center p="8">
        <HStack spacing="12">
          <IconButton isLoading={isLoading} isDisabled={isLoading || page === 0} onClick={() => setPage(page - 1)} aria-label="prev" icon={<ChevronLeftIcon />} />
          <Text fontWeight="black">{page}</Text>
          <IconButton isLoading={isLoading} isDisabled={isLoading || contracts.length < PAGE_LIMIT} onClick={() => setPage(page + 1)} aria-label="next" icon={< ChevronRightIcon />} />
        </HStack>
      </Center>
    </Box>
  )
}

export default Contracts
