import { useCallback, useEffect, useMemo, useState } from 'react';

import { Protocol } from '../types';
import { wagmi } from '../apis';

const useProtocol = (id?: string) => {
  const [protocol, setProtocol] = useState<Partial<Protocol>>()
  const [isLoading, toggleIsLoading] = useState(false)
  const [error, setError] = useState("")

  const isDisabled = useMemo(() => isLoading || (
    !protocol?.name || protocol?.name?.length < 2 || !protocol.logo_url
  ) ? true : false, [protocol, isLoading])

  const getProtocol = useCallback(async () => {
    if (!id) return

    toggleIsLoading(true)
    wagmi({
      method: "get",
      url: `/protocols/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        toggleIsLoading(false)
        setProtocol(res.data)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleIsLoading(false)
      })
  }, [id])

  const addProtocol = useCallback(() => {
    if (isDisabled) return

    toggleIsLoading(true)
    wagmi({
      method: "post",
      url: `/protocols`,
      data: {
        ...protocol,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        toggleIsLoading(false)
        setProtocol(undefined)
      })
      .catch((error) => {
        toggleIsLoading(false)
        setError(error)
        console.log(error)
      })
  }, [protocol, isDisabled])

  const updateProtocol = useCallback(() => {
    if (isDisabled || !protocol?.id) return

    toggleIsLoading(true)
    wagmi({
      method: "put",
      url: `/protocols/${protocol?.id}`,
      data: {
        ...protocol,
      }
    })
      .then((res) => {
        toggleIsLoading(false)
        setProtocol(undefined)
      })
      .catch((error) => {
        toggleIsLoading(false)
        setError(error)
        console.log(error)
      })
  }, [protocol, isDisabled])

  useEffect(() => {
    getProtocol()
  }, [getProtocol])


  return {
    protocol,
    setProtocol,
    getProtocol,
    updateProtocol,
    addProtocol,
    isLoading,
    isDisabled,
    error,
  }
}

export default useProtocol