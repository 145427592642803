import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  Input,
  ButtonGroup,
  Box,
  HStack,
  Center,
  Tag,
  EditableInput,
  EditablePreview,
  Editable,
} from '@chakra-ui/react'
import {
  EditIcon,
  CloseIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'

import { PAGE_LIMIT } from '../consts'
import { Method } from "../types"
import { wagmi } from "../apis"
import useMethods from '../hooks/useMethods'

const Methods = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState("")
  const { methods, getMethods, isLoading } = useMethods({ page })
  const [selected, setSelected] = useState<Method>()

  const updateMethod = async () => {
    if (!selected) return

    wagmi({
      method: "put",
      url: `/methods`,
      data: {
        signature: selected.signature,
        name: selected?.name,
        suffix: selected.suffix
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setSelected(undefined)
        getMethods()
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    if (query.length === 10) {
      navigate(`/methods/${query.toLowerCase()}`)
    }
  }, [query])

  if (isLoading) {
    return (
      <Box>Loading</Box>
    )
  }

  return (
    <Box>
      <Input mt="8" mb="8" value={query} onChange={e => setQuery(e.target.value)} placeholder="Address" />
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th></Th>
            <Th minW="140px">Name</Th>
            <Th>Suffix</Th>
            <Th>Signature</Th>
            <Th>4byte</Th>
            <Th isNumeric>Occurrances</Th>
          </Tr>
        </Thead>
        <Tbody>
          {methods.map((method, index) => {
            const isSelected = selected?.signature === method.signature

            return (
              <Tr key={method.signature || index}>
                <Td>{isSelected ? (
                  <ButtonGroup>
                    <IconButton onClick={() => setSelected(undefined)} aria-label="cancel" icon={<CloseIcon />} />
                    <IconButton onClick={updateMethod} aria-label="submit" icon={<CheckIcon />} />
                  </ButtonGroup>
                ) : <IconButton onClick={() => setSelected(method)} aria-label="edit" icon={<EditIcon />} />}</Td>
                <Td>
                  <Editable defaultValue={method.name || "Name"}>
                    <EditablePreview />
                    <EditableInput placeholder="name" onChange={(e) => selected && setSelected({ ...selected, name: e.target.value })} value={selected ? selected?.name : method.name} />
                  </Editable>
                  {/* {isSelected ? <Input onChange={(e) => selected && setSelected({ ...selected, name: e.target.value })} value={selected?.name}></Input> : <Text fontSize='md'>{method?.name}</Text>} */}
                </Td>
                <Td>
                  <Editable defaultValue={method.suffix || "Suffix"}>
                    <EditablePreview />
                    <EditableInput placeholder="suffix" onChange={(e) => selected && setSelected({ ...selected, suffix: e.target.value })} value={selected ? selected?.suffix : method.suffix} />
                  </Editable>
                </Td>
                {/* {isSelected ? <Input onChange={(e) => selected && setSelected({ ...selected, suffix: e.target.value })} value={selected?.suffix}></Input> : <Text fontSize='md'>{method?.suffix}</Text>}</Td> */}
                <Td>
                  <Text>{method.signature}</Text>
                </Td>
                <Td>
                  {method.four_bytes?.map((functionCall, index) => <Tag key={functionCall || index} mr="1">{`${functionCall} `}</Tag>)}
                </Td>
                <Td isNumeric><Text fontSize='md'>{method.occurrence}</Text></Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Center p="8">
        <HStack spacing="12">
          <IconButton isLoading={isLoading} isDisabled={isLoading || page === 0} onClick={() => setPage(page - 1)} aria-label="prev" icon={<ChevronLeftIcon />} />
          <Text fontWeight="black">{page}</Text>
          <IconButton isLoading={isLoading} isDisabled={isLoading || methods.length < PAGE_LIMIT} onClick={() => setPage(page + 1)} aria-label="next" icon={< ChevronRightIcon />} />
        </HStack>
      </Center>
    </Box>
  )
}

export default Methods
