import { Box, Flex, Stat, StatArrow, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react"
import { useMemo } from "react"
import useStatistics from "../hooks/useStats"

const calculateDelta = (amountNew?: number, amountOld?: number) => amountNew && amountOld ? Math.round((amountNew - amountOld) / (amountOld / 100) * 100) / 100 : 0

const Home = () => {
  const { statistics } = useStatistics()

  const {
    users,
    users_24_hours_ago,
    users_indexed,
    users_indexed_24_hours_ago,
    connections,
    connections_24_hours_ago,
    // transactions,
    // transactions_24_hours_ago,
    methods,
    methods_total,
    collections,
    tokens,
    protocols,
    labeled_contracts,
  } = statistics

  const usersDelta = useMemo(() => calculateDelta(users, users_24_hours_ago), [users, users_24_hours_ago])
  const usersIndexedDelta = useMemo(() => calculateDelta(users_indexed, users_indexed_24_hours_ago), [users_indexed, users_indexed_24_hours_ago])
  const connectionsDelta = useMemo(() => calculateDelta(connections, connections_24_hours_ago), [connections, connections_24_hours_ago])
  // const transactionsDelta = useMemo(() => calculateDelta(transactions, transactions_24_hours_ago), [transactions, transactions_24_hours_ago])

  return (
    <Box p="8">
      <Flex wrap="wrap">
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Verified&nbsp;users</StatLabel>
          <StatNumber>{users}</StatNumber>
          <StatHelpText>
            {usersDelta > 0 && <StatArrow type='increase' />}
            {usersDelta}%
          </StatHelpText>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Indexed&nbsp;addresses</StatLabel>
          <StatNumber>{users_indexed}</StatNumber>
          <StatHelpText>
            {usersIndexedDelta > 0 && <StatArrow type='increase' />}
            {usersIndexedDelta}%
          </StatHelpText>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Total&nbsp;connections</StatLabel>
          <StatNumber>{connections}</StatNumber>
          <StatHelpText>
            {connectionsDelta > 0 && <StatArrow type='increase' />}
            {connectionsDelta}%
          </StatHelpText>
        </Stat>
        {/* <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Transactions&nbsp;indexed</StatLabel>
          <StatNumber>{transactions}</StatNumber>
          <StatHelpText>
            {transactionsDelta > 0 && <StatArrow type='increase' />}
            {transactionsDelta}%
          </StatHelpText>
        </Stat> */}
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Methods&nbsp;named</StatLabel>
          <StatNumber>{methods}</StatNumber>
          <StatHelpText>
            {methods_total}
          </StatHelpText>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>NFT&nbsp;Collections&nbsp;indexed</StatLabel>
          <StatNumber>{collections}</StatNumber>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Tokens&nbsp;indexed</StatLabel>
          <StatNumber>{tokens}</StatNumber>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Protocols</StatLabel>
          <StatNumber>{protocols}</StatNumber>
        </Stat>
        <Stat p="8" m="2" borderRadius="xl">
          <StatLabel>Labeled&nbsp;contracts</StatLabel>
          <StatNumber>{labeled_contracts}</StatNumber>
        </Stat>
      </Flex>
    </Box>
  )
}

export default Home
