import {
  Text,
  Input,
  Tag,
  TagLabel,
  Avatar,
  Link,
  Select,
  ButtonGroup,
  Button,
  HStack,
  Stack,
  Divider,
  Flex,
  Box,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

import useContract from '../hooks/useContract'
import useProtocols from '../hooks/useProtocols'

const Contract = () => {
  const { protocols } = useProtocols()
  const { address } = useParams()
  const { contract, setContract, updateContract, syncContractABI, isDisabled, isLoading } = useContract(address?.toLowerCase())

  const constructor = useMemo(() => contract?.abi?.find(item => item.type === 'constructor'), [contract])
  const events = useMemo(() => contract?.abi?.filter(item => item.type === 'event'), [contract])
  const functions = useMemo(() => contract?.abi?.filter(item => item.type === 'function'), [contract])

  if (isLoading) {
    return (
      <Box>Loading</Box>
    )
  }

  if (!contract) return null

  return (
    <Stack spacing="4" p="4" pb="12">
      <Text fontWeight="bold">Contract: {address}</Text>
      <Input placeholder="name" onChange={(e) => setContract({ ...contract, name: e.target.value })} value={contract.name}></Input>
      <Select value={contract.protocol?.id} onChange={(e) => setContract({ ...contract, protocol: protocols.find(p => p.id.toString() === e.target.value) })} placeholder="Select protocol">
        {protocols.map(protocol => <option value={protocol.id} key={protocol.id}>{protocol.name}</option>)}
      </Select>
      {contract.protocol?.name && (
        <Tag size="md">
          <Avatar
            src={contract.protocol?.logo_url}
            size='2xs'
            name={contract.protocol?.name}
            ml={-1}
            mr={2}
          />
          <TagLabel>{contract.protocol?.name}</TagLabel>
        </Tag>
      )}
      <HStack spacing="2">
        <ButtonGroup isAttached variant="outline">
          <Button as={Link} href={`https://etherscan.io/address/${contract.address}#code`} isExternal rightIcon={<ExternalLinkIcon />} aria-label="etherescan contract">Etherscan</Button>
          {contract.abi && <Button as={Link} href={`https://interface.mypinata.cloud/ipfs/${contract.abi}`} isExternal rightIcon={<ExternalLinkIcon />} aria-label="ipfs pinned contract abi">ABI</Button>}
        </ButtonGroup>
        <ButtonGroup>
          <Button isDisabled={contract.abi ? true : false} isLoading={isLoading} onClick={syncContractABI}>Sync ABI</Button>
          <Button isDisabled={isDisabled} isLoading={isLoading} onClick={updateContract}>Update contract</Button>
        </ButtonGroup>
      </HStack>
      <Divider />
      <Text>Constructor inputs</Text>
      <Flex wrap="wrap">
        {constructor?.inputs.map((input: any, index: number) => <Tag m="1" key={input.name + index}>{input?.name}: {input.type}</Tag>)}
      </Flex>
      <Text>Events:</Text>
      <Flex wrap="wrap">
        {events?.map((event, index) => <Tag m="1" key={event.name + index}>{event.name}</Tag>)}
      </Flex>
      <Text>Functions:</Text>
      <Flex wrap="wrap">
        {functions?.map((func, index) => <Tag m="1" key={func.name + index}>{func.name}</Tag>)}
      </Flex>
    </Stack>
  )
}

export default Contract
