import { useCallback, useEffect, useState } from "react"
import { Protocol } from "../types"
import { wagmi } from "../apis"

const useProtocols = () => {
  const [protocols, setProtocols] = useState<Protocol[]>([])
  const [isLoading, toggleLoading] = useState(false)
  const [error, setError] = useState("")

  const getProtocols = useCallback(async () => {
    toggleLoading(true)
    wagmi({
      method: "get",
      url: `/protocols`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setProtocols(res.data)
        toggleLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        toggleLoading(false)
      })
  }, [])

  useEffect(() => {
    getProtocols()
  }, [getProtocols])

  return { protocols, getProtocols, setProtocols, isLoading, error }
}

export default useProtocols