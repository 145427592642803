import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  Box,
  Image,
  Checkbox,
  Badge,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  IconButton,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from "react"

import { Address } from "../types"
import useWhitelisted from "../hooks/useWhitelisted"
import { wagmi, api } from "../apis"
import { isAddress } from 'ethers/lib/utils'
import { SearchIcon } from '@chakra-ui/icons'

const Whitelisted = () => {
  const toast = useToast()

  const [page, setPage] = useState(0)
  const [query, setQuery] = useState("")
  // const { whitelisted, getWhitelisted, error, isLoading } = useWhitelisted({ page })
  const [searchIsLoading, toggleSearchLoading] = useState(false)
  const [search, setSearch] = useState<Address>()

  const whitelist = async (address: string) => {
    wagmi({
      method: "put",
      url: `/whitelist`,
      data: {
        address,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        toast({
          title: 'Added to the wl',
          description: `Successfully added ${address} to the wl`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'bottom-right',
        })
        // getWhitelisted()
        if (search) {
          getAddress()
        }
      })
      .catch((error) => console.log(error))
  }

  const removeFromWhitelist = async (address: string) => {
    if (!window.confirm("Are you sure?")) {
      return
    }

    wagmi({
      method: "delete",
      url: `/whitelist`,
      data: {
        address,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        // getWhitelisted()
      })
      .catch((error) => console.log(error))
  }

  const getAddress = async () => {
    if (isAddress(query) || query.endsWith('.eth') || query.endsWith('.xyz')) {
      toggleSearchLoading(true)
      api({
        method: "get",
        url: `/${query}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          toggleSearchLoading(false)
          setSearch(res.data)
        })
        .catch((error) => {
          toggleSearchLoading(false)
          console.log(error)
        })
    }
  }

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Search</Tab>
          <Tab>Whitelisted</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex mt="8" mb="8">
              <Input value={query} onChange={e => setQuery(e.target.value)} placeholder="Address" />
              <IconButton ml="4" icon={<SearchIcon />} aria-label="search" onClick={getAddress} />
            </Flex>
            {search && (
              <Flex align="center">
                {search.ens?.avatar_url && <Image src={search.ens?.avatar_url} w="40px" alt={search.ens.domain} mr="2" />}
                <Text mr="2">{search.ens?.domain ? search.ens.domain : search.address}</Text>
                {search.verified && <Badge>Connected</Badge>}
                <Checkbox isDisabled={true} isChecked={search.whitelisted} ml="2" mr="2">Whitelisted</Checkbox>
                {search.whitelisted ? (
                  <Button onClick={() => removeFromWhitelist(search.address)}>Remove</Button>
                ) : (
                  <Button onClick={() => whitelist(search.address)}>Add</Button>
                )}
              </Flex>
            )}
          </TabPanel>
          <TabPanel>
            {/* <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Address</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {whitelisted.map((address: Address) => (
                  <Tr key={address.address}>
                    <Td>
                      {address.ens?.avatar_url && <Image src={address.ens?.avatar_url} w="40px" alt={address.ens.domain} />}
                    </Td>
                    <Td>
                      <Flex>
                        <Text mr="2">{address.ens?.domain ? address.ens.domain : address.address}</Text>
                        {address.verified && <Badge>Connected</Badge>}
                      </Flex>
                    </Td>
                    <Td>
                      <Checkbox isDisabled={true} isChecked={address.whitelisted}>Whitelisted</Checkbox>
                      {address.whitelisted ? (
                        <Button onClick={() => removeFromWhitelist(address.address)}>Remove</Button>
                      ) : (
                          <Button onClick={() => whitelist(address.address)}>Add</Button>
                        )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table> */}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default Whitelisted
