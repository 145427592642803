import useFetch from './useFetch'

export type Data = { date: string, num_verified: number };
export type CumulativeData = Data & { num_verified_cumulative: number };

export type Period = "daily" | "weekly" | "monthly"
export type Range = "week" | "month" | "year" | "all_time"

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  const currentYear = new Date().getFullYear()
  const formatted = new Date(dateString).toLocaleString("en-US", { day: "2-digit", month: "2-digit", year: "2-digit" }).replace(',', '').replaceAll('/', '.')

  if (date.getFullYear() === currentYear) {
    return formatted.slice(0, -3);
  }
  return formatted
}

function formatData(data: Array<Data> | undefined): Array<CumulativeData> {
  if (!data) return []

  let cumulativeSum = 0;
  const cumulativeData = data.map((d) => {
    cumulativeSum += d.num_verified;
    return {
      ...d,
      date: formatDate(d.date),
      num_verified_cumulative: cumulativeSum
    };
  }) as Array<CumulativeData>;
  return cumulativeData;
}

const useNewUsers = (period: Period, range?: Range) => {
  const { data, isLoading, error } = useFetch<Array<Data>>(`/stats/new-users/${period}${range ? `?period=${range}` : ""}`)

  return { data: formatData(data), isLoading, error }
}

export default useNewUsers
