import {
  Box,
  HStack,
} from '@chakra-ui/react'
import { Outlet, Link, useResolvedPath, useMatch, LinkProps } from "react-router-dom";
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAuth } from '../state/auth';

const CustomLink = ({ children, to, ...props }: LinkProps) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link
        style={{ fontWeight: match ? "bold" : "normal", cursor: 'pointer' }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
}

const Layout = () => {
  let { jwt } = useAuth();

  return (
    <Box p="4">
      <HStack justify="space-between">
        {jwt && (
          <HStack spacing="8" p="2" justify="center">
            <CustomLink to="/">Home</CustomLink>
            <CustomLink to="/protocols">Protocols</CustomLink>
            <CustomLink to="/contracts">Contracts</CustomLink>
            <CustomLink to="/methods">Methods</CustomLink>
            <CustomLink to="/tokens">Tokens</CustomLink>
            <CustomLink to="/addresses">Addresses</CustomLink>
            <CustomLink to="/traction">Traction</CustomLink>
          </HStack>
        )}
        <ConnectButton />
      </HStack>
      <Outlet />
    </Box>
  );
}

export default Layout